<template>
    <div class="thematic-page">
        <section class="container">
            <p class="title-cat-big" v-if="theme">{{themeBySlug.name}} </p>
            <section class="container-grid">
                <template v-if="news || opinions || interviews || coverages || photos || files || others">
                <template v-if="news">
                    <p v-if="news" class="title-dest-underline w-100">Noticias</p>
                    <div class="grid-3">
                        <template v-for="(notice, index) in Object.values(news).slice(0, 3)" :key="index">
                            <newVerticalImg :dataContent="notice" :typeMedia="notice.file_type"></newVerticalImg>
                        </template>
                    </div>
                    <div class="row-center w-100">
                        <router-link class="btn-white" :to="{ name: 'news',query: { theme: themeBySlug.slug }}" title="Ir a página de noticias" alt="Ir a página de noticias">Más noticias</router-link>
                    </div>
                </template>
                <template v-if="opinions">
                    <p class="title-dest-underline w-100" v-if="opinions">Opinión</p>
                    <div class="grid-3 w-100">
                        <template v-for="(opinion, key, index) in opinions" :key="key">
                            <opinionSmall v-if="index >= 0 && index < 3" :dataContent="opinion"></opinionSmall>
                        </template>
                    </div>
                    <div class="row-center w-100">
                        <router-link class="btn-white" :to="{ name: 'opinion',query: { theme: themeBySlug.slug }}" title="Ir a página de opiniones" alt="Ir a página de opiniones">Más opiniones</router-link>
                    </div>
                </template>
                <template v-if="interviews">
                    <p v-if="interviews" class="title-dest-underline w-100">Entrevistas</p>
                    <div class="grid-3 w-100">
                        <template v-for="(interview, key, index) in interviews" :key="key">
                            <newVerticalImg v-if="index >= 0 && index < 3" :dataContent="interview"></newVerticalImg>
                        </template>
                    </div>
                    <div class="row-center w-100">
                        <router-link class="btn-white" :to="{ name: 'interviews',query: { theme: themeBySlug.slug } }" title="Ir a página de Entrevistas" alt="Ir a página de Entrevistas">Más entrevistas</router-link>
                    </div>
                </template>
                <template v-if="coverages">
                    <p class="title-dest-underline w-100" template v-if="coverages">Reportajes</p>
                    <div class="grid-3 w-100">
                        <template v-for="(coverage, key, index) in coverages" :key="key">
                            <newVerticalImg v-if="index >= 0 && index < 3" :dataContent="coverage"></newVerticalImg>
                        </template>
                    </div>
                    <div class="row-center w-100">
                        <router-link class="btn-white" :to="{ name: 'reports',query: { theme: themeBySlug.slug }}" title="Ir a página de reportajes" alt="Ir a página de reportajes">Más reportajes</router-link>
                    </div>
                </template>
                <template v-if="others">
                    <p class="title-dest-underline w-100" template v-if="others">Artículos generales</p>
                    <div class="grid-3 w-100">
                        <template v-for="(coverage, key, index) in others" :key="key">
                            <newVerticalImg v-if="index >= 0 && index < 3" :dataContent="coverage"></newVerticalImg>
                        </template>
                    </div>
                    <div class="row-center w-100">
                        <router-link class="btn-white" :to="{ name: 'others',query: { theme: themeBySlug.slug }}" title="Ir a página de Artículos generales" alt="Ir a página de Artículos generales">Más artículos generales</router-link>
                    </div>
                </template>
                <template v-if="photos">
                    <p class="title-dest-underline w-100" v-if="photos">Fotografías</p>
                    <div class="grid-3 w-100">
                        <template v-for="(photo, key) in photos" :key="key">
                            <newVerticalImg v-if="key >= 0 && key < 3" :dataContent="photo[1]" :typeMedia="photo[1].file_type"></newVerticalImg>

                        </template>
                    </div>
                    <div class="row-center w-100">
                        <router-link class="btn-white" :to="{ name: 'audiovisuals',query: { theme: themeBySlug.slug } }" title="Ir a página de Imago CERMI" alt="Ir a página de Imago CERMI">Ver más</router-link>
                    </div>
                </template>
                <template v-if="files">
                    <p v-if="files" class="title-dest-underline w-100">Documentos</p>
                    <div class="grid-3 w-100">
                        <template v-for="(file, key, index) in files" :key="key">
                            <documentSimpleText :dataContent="file" v-if="index >= 0 && index < 3"></documentSimpleText>
                        </template>
                    </div>
                    <div class="row-center w-100">
                        <router-link class="btn-white" :to="{ name: 'documents',query: { theme: themeBySlug.slug} }" title="Ir a página de documentos" alt="Ir a página de documentos">Más documentos</router-link>
                    </div>
                </template>
                </template>
                <div v-else class="container-noResult w-100">
                    <div class="w-100 row-center">
                        <p class="title-dest-underline">No hay resultados</p>
                    </div>
                </div>
                <!-- <paginator v-if="news" class="w-100" :pageActual="newPage" :totalItems="NewsMaxItems" @clicked="changePages"></paginator> -->
            </section>
            <gridContentsFooter :slug="slug"></gridContentsFooter>
        </section>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions,
        mapMutations
    } from 'vuex';
    import store from '@/store';
    import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
    import gridContentsFooter from "@/components/content-footer-dinamic.vue";
    import opinionSmall from "@/components/opinions/view-opinion-small.vue";
    // import paginator from "@/components/paginator.vue";
    import utils from "@/utils/utils.js";
    import documentSimpleText from "@/components/documents/document-view-simple-text.vue";
    export default {
        name: "thematic-page",
        components: {
            newVerticalImg,
            opinionSmall,
            gridContentsFooter,
            // paginator,
            documentSimpleText
        },
        props: ['slug', 'filterTime'],
        computed: {
            ...mapGetters({
                newPage: 'contents/getNewViewPage',
                theme: 'contents/getThemeSelected'
            }),
            themeBySlug: function () {
                return store.getters['contents/getThemeSlug'](this.slug);
            },
            news: function () {
                return store.getters['contents/getNews'](this.newPage)
            },
            interviews: function () {
                return store.getters["contents/getInterviews"](1);
            },
            coverages: function () {
                return store.getters["contents/getCoverages"](1);
            },
            medias: function () {
                return store.getters["contents/getMedias"](1);
            },
            files: function () {
                return store.getters["contents/getFiles"](1);
            },
            others: function () {
                return store.getters["contents/getOthers"](1);
            },
            photos() {
                return null;
            },
            opinions: function () {
                return store.getters['contents/getOpinions'](1)
            },
            NewsMaxItems() {
                return store.getters["contents/getPagesNews"]();
            }
            
        },
        methods: {
            ...mapActions({
                loadNews: 'contents/loadNews',
                loadEvenTheThingsWithHandtStolen: "contents/loadEvenTheThingsWithHandtStolen",
            }),
            ...mapMutations({
                setPageNew: 'contents/setPageNew',
                setTheme: 'contents/setTheme'
            }),
            changePages(item) {
                this.setPageNew(item);
                this.loadNews({
                    page: this.newPage,
                });
                utils.LoaderScrollToTop();
            },
        },
        mounted() {
            this.loadEvenTheThingsWithHandtStolen({
                page: this.newPage,
            });
            this.setTheme(this.themeBySlug);
        },
        watch: {
            themeBySlug: function (newVal, oldVal) {
                this.setTheme(this.themeBySlug);
            },
            filterTime: function (newVal, oldVal) {
                this.loadEvenTheThingsWithHandtStolen();
            }
        },
    };
</script>
<style>
    .thematic-page .container-grid {
        padding-bottom: 0;
    }

    .thematic-page .title-dest-underline {
        margin-top: 25px;
    }

    .thematic-page .new-featured-vertical-img .content-new {
        margin-top: 15px;
    }

    .thematic-page .content-footer-dinamic {
        margin-top: 30px;
    }


    .thematic-page .title-cat-big {
        border-top: 0;
        border-bottom: 1px solid rgba(0, 105, 180, 0.08);
        padding-top: 0;
        margin-top: 0;
    }

    .body-app {
        padding-top: 10px !important;
    }
</style>